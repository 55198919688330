<template>
  <div class="case-introduce-main">
    <div class="case-introduce-con">
      <template v-if="isMobile">
        <div class="title">
          <span>{{ config.name }} </span>
          <span class="config-title">{{ config.title }} </span>
        </div>
        <div class="m-remake">
          <div>从初创团队到大型集团</div>
          <div>助力来自各行各业的组织更便捷使用合规产品</div>
        </div>
      </template>

      <template v-else>
        <div class="title">
          <span class="config-title">{{ config.title }} </span>
          <span>{{ config.name }} </span>
        </div>
        <div class="remake">
          {{ config.remake }}
        </div>
      </template>

      <CompanyScale :data="config.scaleData" :isMobile="isMobile"></CompanyScale>
      <CustomSwiper :isMobile="isMobile"></CustomSwiper>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./CaseIntroduce.scss";
@import "@/pages/css/mobile-base.scss";
@media (max-width: $MobileWidth) {
  @import "@/pages/css/mobile-case.scss";

  .case-introduce-main {
    background-image: url("../../../images/newHome/case/mobile-bg.png");
  }
}
</style>

<script>
import CompanyScale from "./CompanyScale";
import CustomSwiper from "../../../components/swiper/CustomSwiper.vue";
import { mapState } from "vuex";
export default {
  name: "caseIntroduce",
  components: {
    CompanyScale,
    CustomSwiper,
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      config: {
        title: "行业头部企业的共同选择",
        name: "iLaw合规",
        remake:
          "从初创团队到大型集团，助力来自各行各业的组织更便捷使用合规产品",
        scaleData: [
          {
            number: 15,
            unit: "年",
            test: "法律科技经验",
          },
          {
            number: 800,
            unit: "+",
            test: "法律/计算机双背景人才",
          },
          {
            number: 10,
            unit: "+",
            test: "法律科技产品",
          },
          {
            number: 4000,
            unit: "+",
            test: "律所客户",
          },
          {
            number: 300,
            unit: "+",
            test: "企业法务部",
          },
        ],
      },
    };
  },
};
</script>
