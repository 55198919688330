<template>
  <div>
    <CaseIntroduce></CaseIntroduce>
    <CustomerComponent></CustomerComponent>
    <MobileFooter v-if="isMobile"></MobileFooter>
    <footerCom v-else></footerCom>
    <reservationCom></reservationCom>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CaseIntroduce from "./components/CaseIntroduce.vue";
import CustomerComponent from "./components/CustomerComponent.vue";
import footerCom from "../../components/footerCom/index.vue";
import reservationCom from "../../components/reservationCom/index.vue";
import MobileFooter from "../../components/mobile-footer/index"

export default {
  metaInfo: {
    title: "iLaw合规_客户案例_法律合规风险治理_数据隐私_隐私合规",
    meta: [
      {
        name: "keywords",
        content:
          "iLaw,合规,法律合规风险治理,数据隐私,隐私合规,GDPR,法规风险案例,腾讯,特步,浙江财开,中建,青岛地铁,欢聚,阿里巴巴,中兴,优必选,青岛地铁",
      },
      {
        name: "description",
        content: "iLaw 助力来自各行各业的组织更便捷使用合规产品",
      },
    ],
  },
  name: "caseIndex",
  components: {
    CaseIntroduce,
    CustomerComponent,
    footerCom,
    reservationCom,
    MobileFooter,
  },
  computed: {
    ...mapState(['isMobile']),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
