<template>
  <div :class="{ 'mobile-scale-main': isMobile }">
    <template v-if="isMobile">
      <div class="m-scale-main">
        <div class="data-1">
          <div v-for="(item, index) in mobileScaleData.row1" :key="index" class="data-item"
            style="font-family: DIN Alternate">
            <div class="number">
              <span class="num">{{ item.number }}</span>
              <span class="unit" :class="index == 0 ? 'ml-10' : 'ml-3'">
                {{ item.unit }}
              </span>
            </div>
            <div class="test">{{ item.test }}</div>
          </div>
        </div>

        <div class="data-2">
          <div v-for="(item, index) in mobileScaleData.row2" :key="index" class="data-item"
            style="font-family: DIN Alternate">
            <div class="number">
              <span class="num">{{ item.number }}</span>
              <span class="unit ml-3">
                {{ item.unit }}
              </span>
            </div>
            <div class="test">{{ item.test }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="scale-main">
        <div v-for="(item, index) in data" :key="index" class="data-item" style="font-family: DIN Alternate">
          <div class="number">
            <span class="num">{{ item.number }}</span>
            <span class="unit" :class="index == 0 ? 'ml-10' : 'ml-3'">
              {{ item.unit }}
            </span>
          </div>
          <div class="test">{{ item.test }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "./CompanyScale.scss";
@import "@/pages/css/mobile-base.scss";
@media (max-width: $MobileWidth) {
  @import "@/pages/css/mobile-case.scss";
}

.mobile-scale-main {
  width: 100%;
}
</style>

<script>
export default {
  name: "companyScale",
  props: {
    data: {
      type: Array,
    },
    isMobile: {
      type: Boolean,
    }
  },
  data() {
    return {
      mobileScaleData: {
        row1: [
          {
            number: 15,
            unit: "年",
            test: "法律科技经验",
          },
          {
            number: 10,
            unit: "+",
            test: "法律科技产品",
          },
          {
            number: 300,
            unit: "+",
            test: "企业法务部",
          },
        ],
        row2: [
          {
            number: 800,
            unit: "+",
            test: "法律/计算机双背景人才",
          },
          {
            number: 4000,
            unit: "+",
            test: "律所客户",
          },
        ]
      },
    }
  }
};
</script>
